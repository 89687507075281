import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
    MenuItem,
} from '@mui/material'
import {
    useNotify,
    useUnselectAll,
    useRefresh,
    useGetList,
    useUpdateMany,
} from 'react-admin'
import { isEmpty, get } from 'lodash-es'
import { format, parseISO } from 'date-fns'

import { sortedReleaseNames, ReleaseOptionSelector } from '../../../common'
import { truncate } from '@thefront/pandipackV2'

/**
 * Based on the integration, render dialog that allows user to select relevant releases.
 */
export default React.forwardRef((props, ref) => {
    const { selectedIds, resource, integration, handleCloseMenu } = props
    const [open, setOpen] = useState(false)
    const [selectedRelease, setSelectedRelease] = useState('')
    const [selectedChannel, setSelectedChannel] = useState('')
    const unselectAll = useUnselectAll('tenants')
    const [updateMany] = useUpdateMany()
    const notify = useNotify()
    const refresh = useRefresh()
    const {
        loading: integrationReleasesLoading,
        data: integrationReleases,
    } = useGetList('integrationreleases', {
        filter: { integration_id: integration?.id },
        pagination: { page: 1, perPage: 500 },
    })

    // state for radio buttons
    const [releaseOption, setReleaseOption] = useState('release')

    const bulkUpdateRelease = () =>
        updateMany(
            resource,
            {
                ids: selectedIds,
                data: {
                    integration_release: selectedRelease,
                    integration_release_channel: selectedChannel || null,
                },
            },
            {
                // On success, close dialog, close menu and unselect tenants
                onSuccess: (data) => {
                    unselectAll()
                    setOpen(false)
                    handleCloseMenu()
                    setSelectedRelease('')
                    notify('Tenants Updated!')
                    refresh()
                },
                onError: (error) => {
                    console.error('Error: ', error)
                    notify('Error updating tenants.')
                },
            }
        )

    const handleReleaseChange = (ev) => {
        setSelectedRelease(ev.target.value)
        setSelectedChannel('')
    }

    const handleChannelChange = (ev) => {
        setSelectedChannel(ev.target.value)
        setSelectedRelease('')
    }

    const handleClickOpen = () => {
        setOpen(true)
        handleCloseMenu()
    }

    const handleClose = () => {
        setOpen(false)
        handleCloseMenu()
    }

    if (!integration) {
        return <MenuItem disabled> Upgrade release </MenuItem>
    }

    const sortedReleases = sortedReleaseNames(integrationReleases)
    const { defaultReleaseId } = integration || {}

    return (
        !integrationReleasesLoading && (
            <div>
                <MenuItem variant="outlined" onClick={handleClickOpen}>
                    {' '}
                    Upgrade release{' '}
                </MenuItem>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle> Integration Release </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Choose a release option for the selected tenants.
                        </DialogContentText>
                        <ReleaseOptionSelector
                            releaseOption={releaseOption}
                            setReleaseOption={setReleaseOption}
                        />
                        {releaseOption === 'channel' && (
                            <Select
                                onChange={handleChannelChange}
                                value={selectedChannel}
                                displayEmpty
                                fullWidth
                            >
                                <MenuItem value="" key="placeholder">
                                    {' '}
                                    <em>Select Channel</em>{' '}
                                </MenuItem>
                                {['Latest', 'Default'].map((value) => (
                                    <MenuItem
                                        value={value}
                                        key={value}
                                        disabled={
                                            value === 'Default' &&
                                            (defaultReleaseId === null ||
                                                defaultReleaseId === -1)
                                        }
                                    >
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {releaseOption === 'release' && (
                            <Select
                                onChange={handleReleaseChange}
                                value={selectedRelease}
                                displayEmpty
                                fullWidth
                                disabled={Boolean(selectedChannel)}
                            >
                                <MenuItem value="" key="placeholder">
                                    {' '}
                                    <em>Select Release</em>{' '}
                                </MenuItem>
                                {!isEmpty(sortedReleases) &&
                                    sortedReleases.map((release) => {
                                        const releaseLabel =
                                            get(release, 'tag') || release.name
                                        return (
                                            <MenuItem
                                                value={release.id}
                                                key={release.id}
                                            >
                                                {truncate(releaseLabel, 20)} (
                                                {get(release, 'createdDate') &&
                                                    format(
                                                        parseISO(
                                                            get(
                                                                release,
                                                                'createdDate'
                                                            )
                                                        ),
                                                        'M/dd/yyyy h:mm:ss aa'
                                                    )}
                                                )
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button className="clearButton" onClick={handleClose}>
                            {' '}
                            CANCEL{' '}
                        </Button>
                        <Button
                            onClick={bulkUpdateRelease}
                            disabled={!(selectedRelease || selectedChannel)}
                        >
                            {' '}
                            SAVE{' '}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    )
})
